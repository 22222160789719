<template>
  <base-layout title="Log In">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12" size-md="6" offset-md="3" size-lg="4" offset-lg="4">
          <!-- <ion-card class="ion-margin-top"> -->
          <ion-img :src="logo" class="logo"></ion-img>
          <ion-card-header class="ion-text-center">
            <ion-card-subtitle> Student </ion-card-subtitle>
            <ion-card-title> {{ capacitorConfig.schoolName }} </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <form @submit.prevent="login" action="">
              <ion-list>
                <ion-item>
                  <ion-label position="floating">Username</ion-label>
                  <ion-input type="text" v-model="username"></ion-input>
                </ion-item>
                <ion-text
                  color="danger ion-padding"
                  v-if="v$.username.$error && validationError"
                >
                  Username is invalid
                </ion-text>

                <ion-item>
                  <ion-label position="floating">Password</ion-label>
                  <ion-input type="password" v-model="password"></ion-input>
                </ion-item>
                <ion-text
                  color="danger ion-padding"
                  v-if="v$.password.$error && validationError"
                >
                  Password is required
                </ion-text>
              </ion-list>
              <ion-button
                type="submit"
                expand="full"
                class="ion-margin-vertical"
              >
                Submit
              </ion-button>
            </form>
          </ion-card-content>
          <!-- </ion-card> -->
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  // IonCard,
  IonImg,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { required, alphaNum } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import capacitorConfig from "../../../../capacitor.config.json";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    // IonCard,
    IonImg,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonText,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  mounted() {
    // Bug fix for unclickable input fields
    let mainContent = document.getElementById("main-content");
    mainContent.classList.remove("menu-content-open");
  },

  data() {
    return {
      capacitorConfig,
      logo: "assets/img/logo.png",

      username: null,
      password: null,
      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  validations() {
    return {
      username: {
        required,
        alphaNum,
      },
      password: {
        required,
      },
    };
  },

  methods: {
    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: ["Close"],
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async login() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let credentials = {
        username: this.username,
        password: this.password,
      };

      const authenticating = await this.loader("Authenticating...");
      await authenticating.present();

      try {
        await this.$store.dispatch("auth/login", credentials);
        authenticating.dismiss();
        this.$router.replace({ name: "dashboard" });
      } catch (error) {
        authenticating.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        await alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },
  },
};
</script>

<style scoped>
.logo {
  max-width: 12rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
</style>